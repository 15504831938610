import React, { createRef, useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import Slide from './Slide'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import Swiper core and required modules
import SwiperCore, { Mousewheel, Pagination, EffectCreative } from 'swiper'

// install Swiper modules
SwiperCore.use([Mousewheel, Pagination, EffectCreative])

const Section = styled.section`
	${tw`w-full h-screen overflow-y-auto`}
	height: ${({ cssHeight }) => (!!cssHeight ? cssHeight : '100vh')};
	@-moz-document url-prefix() {
		scrollbar-width: none;
	}
	::-webkit-scrollbar {
		display: none;
		scrollbar-width: none;
	}

	.swiper {
		${tw`h-screen bg-black`}
		height: ${({ cssHeight }) => (!!cssHeight ? cssHeight : '100vh')};
	}
	.swiper-pagination {
		right: 25px;
	}
	.year {
		${tw`hidden`}
	}
	.swiper-pagination-bullet {
		margin: 20px 0 !important;
		transition: transform 0.15s ease-in-out;
		${tw`relative w-6 h-6 text-white duration-300 scale-50 bg-white cursor-pointer opacity-80 
		text-[12px] leading-[24px] ring-0 filter drop-shadow-burger`};

		&::after {
			content: '';
			${tw`absolute -inset-4`}
		}
	}

	.swiper-pagination-bullet-active {
		transform: scale(0.8);
		opacity: 1;
	}

	@media screen and (min-width: 768px) {
		.swiper-pagination {
			right: 30px;
		}
		.year {
			${tw`absolute block right-[36px] text-[18px]`}
		}
		.swiper-pagination-bullet {
			transform: scale(0.7);
		}
		.swiper-pagination-bullet-active {
			transform: scale(1);
		}
	}
`
const SlideWrap = styled.div``
const ScrollyTelling = ({ data }) => {
	const [height, setHeight] = useState(null)
	const cssHeight = `${height}px`
	const getHeight = () => {
		setHeight(window.innerHeight)
	}
	useEffect(() => {
		if (typeof window !== 'undefined') {
			getHeight()
			window.addEventListener('resize', getHeight)
			return () => {
				window.removeEventListener('resize', getHeight)
			}
		}
	}, []) //
	const [sorted, setSorted] = useState(null)
	const stories = data.stories

	const sectionRef = stories.map(() => createRef())
	const sortStories = (items) => {
		const _sorted = items.sort((a, b) => {
			return a.year - b.year
		})
		setSorted(_sorted)
	}
	useEffect(() => {
		if (sorted) return
		sortStories(stories)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `<div
					class='${className} my-bullet'
				>
					<div class="year">${stories[index].year}</div>
				</div>`
		},
	}
	return (
		<Section cssHeight={cssHeight}>
			<Swiper
				direction={'vertical'}
				slidesPerView={1}
				mousewheel={true}
				pagination={pagination}
				className='mySwiper'
				grabCursor={true}
				speed={600}
				effect={'creative'}
				creativeEffect={{
					prev: {
						shadow: true,
						translate: [0, 0, -400],
					},
					next: {
						translate: [0, '100%', 0],
					},
				}}
				longSwipes={true}
				height={height}
				cssHeight={cssHeight}
			>
				{stories.map((story, index) => {
					return (
						<SwiperSlide key={`storyslide-${index}`}>
							<SlideWrap name={`${story.title}`} ref={sectionRef[index]} className='h-screen'>
								<Slide story={story} index={index} cssHeight={cssHeight} />
							</SlideWrap>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</Section>
	)
}
export default ScrollyTelling
