import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ScrollyTelling from '../components/modules/ScrollyTelling/ScrollyTelling'
import NavBar from '../components/root/NavBar'

// markup
const ScrollytellingPage = () => {
  const data = useStaticQuery(graphql`
  { 
    main: wpStories(databaseId: { eq: 1053 }) {
      ...PostStoriesFragment
      ...StorySlidesFragment
      pageOptionsPageType {
        pageType
      }
    }
  }
	`)
  const page = data.main
	return (
			<>
			<NavBar />
			<ScrollyTelling data={page.postTypeStories} />
		</>
	)
}

export default ScrollytellingPage
