import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Stripes from '@elements/Stripes'
import TextBox from './parts/TextBox'
import ImageBox from './parts/ImageBox'
import VideoBox from './parts/VideoBox'
import { useInView } from 'react-intersection-observer'
import tw, { styled } from 'twin.macro'
import Lightbox from '@elements/Lightbox'
import { useBreakpoint } from '@hooks/useBreakpoint'
import decor from '@images/decor.png'

const Wrap = styled.div`
	${tw`relative w-full h-screen`};
	height: ${({ cssHeight }) => (!!cssHeight ? cssHeight : '100vh')};
`
const Background = styled.div`
	${tw`absolute top-0 left-0 w-full h-screen bg-dark`};
	height: ${({ cssHeight }) => (!!cssHeight ? cssHeight : '100vh')};
`
const Slide = ({ story, cssHeight }) => {
	const [imageActive, setImageActive] = useState(false)
	const { isMobile } = useBreakpoint()

	const [ref] = useInView({ threshold: 0 })

	return (
		<Wrap ref={ref} cssHeight={cssHeight}>
			<div className='w-full h-screen'>
				<Background cssHeight={cssHeight}>
					{story.backgroundImage?.localFile && !isMobile && <GatsbyImage image={getImage(story.backgroundImage?.localFile)} className='object-cover w-full h-full' alt='' />}
					{isMobile && <GatsbyImage image={getImage(story.backgroundImageMobile?.localFile ?? story.backgroundImage?.localFile)} className='object-cover w-full h-full' alt='' />}
					<Stripes className='hidden lg:block bottom-10' />
				</Background>
				{(story.textBox.headline || story.textBox.text) && <TextBox data={story.textBox} year={story.year} />}
				{story.imageBox && !isMobile && <ImageBox data={story.imageBox} onClick={() => setImageActive(true)} />}
				{story.imageBox && !isMobile && <Lightbox image={story.imageBox ?? story.videoBox} lightboxOpen={imageActive} handleClose={() => setImageActive(false)} />}
				{story.videoBox && !isMobile && <VideoBox data={story.videoBox} onClick={() => setImageActive(true)} />}
				<img src={decor} className='absolute right-0 object-contain w-[200px] lg:w-auto bottom-1/4 lg:bottom-20' alt='' />
			</div>
		</Wrap>
	)
}

export default Slide
