import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import Logo from '@root/Logo'
import throttle from 'lodash/debounce'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import Navigation from '@root/Navigation'
import NavBurger from '@elements/NavBurger'
import { useStateContext } from '@context/stateContext'
import LangSwitcher from '@elements/LangSwitcher'

function NavBar({ translationSlug = null }) {
	const { navState, navStateHandle, language } = useStateContext()
	const location = useLocation()
	const locationPathName = location.pathname

	useEffect(() => {
		navStateHandle(false)
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	const [pageScrolled, setPageScrolled] = useState(locationPathName !== '/' ? true : false)
	const checkPageScrolled = (event) => {
		setPageScrolled(window.scrollY ? true : false)
	}
	useEffect(() => {
		if (locationPathName !== '/') {
			setPageScrolled(true)
		} else {
			setPageScrolled(window.scrollY ? true : false)
		}
		checkPageScrolled()
	}, [locationPathName])
	const onWindowScroll = throttle(checkPageScrolled, 50, {
		trailing: true,
		leading: true,
	})
	useEffect(() => {
		window.addEventListener('scroll', onWindowScroll.bind(this))
		return () => window.removeEventListener('scroll', onWindowScroll)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<NavBarWrap pagescrolled={`${pageScrolled}`}>
			<MotionLink to={`${language === 'de' ? '/' : '/en/'}`}>
				<Logo className={pageScrolled ? 'w-[130px] sm:w-[185px]' : 'w-[185px]'} />
			</MotionLink>
		</NavBarWrap>
	)
}

export default NavBar

const Overlay = tw(motion.div)`fixed inset-0 z-10 bg-dark/50 `
const MotionLink = tw(Link)`block`
const NavBarWrap = styled(motion.div)(({ pagescrolled }) => [
	tw`fixed -top-0.5 z-[1000] flex items-center justify-center w-full transition-all duration-300`,
	pagescrolled === 'true' && tw`bg-white shadow-xl`,
])
