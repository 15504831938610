import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CloseIcon from './CloseIcon'

const StyledCloseIcon = tw(CloseIcon)`absolute -right-5 top-[-50px] cursor-pointer px-5 py-3.5`
const LightboxContainer = styled(motion.div)`
	${tw`relative top-0 w-[90vw] max-w-[700px] 3xl:max-w-[1024px] bg-gray-50 rounded-[1px]`};
`
const Backdrop = styled(motion.div)`
	${tw`fixed top-0 left-0 h-screen w-full bg-[rgba(60, 60, 60, 0.8)] flex justify-center items-center pb-[30px]`};
`
const ImageWrap = tw.div`relative w-full h-auto`
const RatioWrap = tw.div`relative w-full`

const LightboxImage = ({ data }) => {
	return (
		<ImageWrap>
			<RatioWrap className='z-0 aspect-w-16 aspect-h-9'>
				<GatsbyImage className='object-cover w-full h-full' image={getImage(data.localFile)} alt='' />
			</RatioWrap>
		</ImageWrap>
	)
}
const Lightbox = ({ handleClose, lightboxOpen, image, video }) => {
	const lightboxVariants = {
		init: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			transition: {
				duration: 0.3,
			},
		},
		exit: {
			opacity: 0,
		},
	}
	const dropIn = {
		hidden: {
			y: '-100vh',
		},
		visible: {
			y: 0,
			transition: {
				type: 'tween',
				ease: 'easeInOut',
				duration: 0.4,
			},
		},
		exit: {
			y: '-100vh',
			transition: {
				type: 'tween',
				ease: 'easeInOut',
				duration: 0.4,
			},
		},
	}
	return (
		<motion.div variants={lightboxVariants} initial='init' animate={lightboxOpen ? 'animate' : 'init'} exit='exit' tw='z-[2000] absolute'>
			<AnimatePresence>
				{lightboxOpen && (
					<Backdrop onClick={handleClose}>
						<LightboxContainer variants={dropIn} initial='hidden' animate='visible' exit='exit'>
							<StyledCloseIcon onClick={handleClose} />
							{!!image && <LightboxImage data={image} />}
							{!!video && <LightboxImage data={video} />}
						</LightboxContainer>
					</Backdrop>
				)}
			</AnimatePresence>
		</motion.div>
	)
}

export default Lightbox
