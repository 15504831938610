import React from 'react'
import tw, { styled, css } from 'twin.macro'
import parse from 'html-react-parser'
import { motion, AnimatePresence } from 'framer-motion'
import Chevron from '@elements/Chevron'
import { useInView } from 'react-intersection-observer'
import { useBreakpoint } from '@hooks/useBreakpoint'
import Stripes, { stripesHeight } from '@elements/Stripes'
import { strings } from '../../../../static/strings'
import { useStateContext } from '@context/stateContext'

const WrapBox = styled(motion.div)`
	${tw`relative z-20 flex self-end w-full lg:absolute lg:bottom-0 lg:left-0 lg:top-[12vh] 3xl:top-spacingXl-lg lg:w-1/3 xl:w-1/4`}
`
const Scrollbar = css`
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: #3c3c3c;
	}
	&::-webkit-scrollbar-thumb {
		background: #cccccc;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #f5f5f5;
	}
	@-moz-document url-prefix() {
		scrollbar-width: 6px;
		scrollbar-color: #cccccc #3c3c3c;
	}
`
const Box = styled(motion.div)`
	${tw`w-full overflow-hidden lg:w-auto lg:h-auto`}
`
const Content = tw.div`pl-2 pr-8 lg:px-1 2xl:px-0 lg:mt-8 w-full max-w-screen-xl mx-auto flex flex-col`
const Headline = tw.p`uppercase font-futuraBold text-[18px] leading-[20px] lg:text-3xl text-white lg:mb-8`
const TextWrap = styled(motion.div)`
	${tw`relative w-full h-0 2xl:mx-auto lg:h-auto max-h-[45vh] lg:max-h-[40vh]`}
	overflow: ${({ animate }) => (animate === 'open' ? 'auto' : 'hidden')};
	overflow: ${({ animate }) => (animate === 'open' ? 'overlay' : 'hidden')}; //adding a star to label when input is required
	${Scrollbar}
`
const Text = tw(motion.p)`h-auto pr-5 text-sm text-white mt-7 lg:mt-0 2xl:px-4 xl:text-copyXl`
const Flex = tw.div`px-5 flex items-center gap-4`
const ShowMore = tw(motion.div)`uppercase relative lg:mx-5 xl:mx-0 pt-2 mt-3 lg:mt-8 text-sm cursor-pointer text-mediumDark tracking-1 md:tracking-2 
before:absolute before:w-24 before:bottom-full before:left-0 before:h-px before:bg-light`
const MobileYear = tw.span`inline-block lg:hidden mb-3 text-white text-[16px] leading-[30px]`
const StyledStripes = styled(Stripes)`
	${tw`lg:hidden`};
	top: calc(-${stripesHeight.mobile} + 33px); //33px - width of a mobile stripe
	@media screen and (min-width: 768px) {
		top: calc(-${stripesHeight.md} + 33px);
	}
`

const TextBox = ({ data, year, className }) => {
	const module = data
	const { isTablet } = useBreakpoint()
	const [isExpandable, setIsExpandable] = React.useState(false)
	const [isExpanded, setIsExpanded] = React.useState(false)
	const textRef = React.useRef(null)
	const { language } = useStateContext()

	const checkTextExpandable = () => {
		setIsExpandable(isTablet ? true : textRef.current.scrollHeight > 250)
	}
	React.useEffect(() => {
		const currentRef = textRef.current
		const stopPropagation = (e) => e.stopPropagation()
		currentRef.addEventListener('mousewheel', stopPropagation)
		currentRef.addEventListener('touchmove', stopPropagation)
		return () => [currentRef.removeEventListener('mousewheel', stopPropagation), currentRef.removeEventListener('touchmove', stopPropagation)]
	}, [])

	React.useEffect(() => {
		checkTextExpandable()
	}, [isTablet]) // eslint-disable-line react-hooks/exhaustive-deps

	const variants = {
		open: {
			height: 'auto',
			transition: {
				duration: 0.5,
			},
		},
		closed: {
			height: isTablet ? 0 : 250,
			transition: {
				duration: 0.5,
			},
		},
	}

	const [textBoxAnimation, setTextBoxAnimation] = React.useState(false)

	const [ref, isVisible] = useInView({ threshold: 0.51 })

	const setBoxVisible = () => {
		setTextBoxAnimation(true)
	}

	React.useEffect(() => {
		if (isVisible) {
			setBoxVisible()
		}
	}, [isVisible])

	const boxVariants = {
		init: {
			x: '-100%',
		},
		animate: {
			x: '0%',
			transition: {
				type: 'tween',
				bounce: 0,
				delay: 0.2,
				duration: 0.3,
			},
		},
	}
	const stripesTransition = {
		transitionGrey: {
			duration: 0.5,
			delay: 0.8,
		},
		transitionBlue: {
			duration: 0.5,
			delay: 0.5,
		},
	}

	return (
		<div tw='absolute bottom-0 left-0 lg:static'>
			<StyledStripes customTransition={stripesTransition} />
			<WrapBox ref={ref} className={className}>
				<Box variants={boxVariants} initial='init' animate={textBoxAnimation ? 'animate' : 'init'}>
					<motion.div className='pt-5 pb-5 lg:pb-8 wrap bg-greyStripe lg:pt-spacingM-xs px-offset-xs'>
						<Content>
							<MobileYear>{year}</MobileYear>
							{module.headline && <Headline>{parse(module.headline)}</Headline>}
							<TextWrap ref={textRef} initial='closed' variants={variants} animate={isExpanded ? 'open' : 'closed'}>
								<AnimatePresence initial={false}>
									{module.text && (
										<Text expanded={`${isExpanded}`} expandable={`${isExpandable}`}>
											{parse(module.text)}
										</Text>
									)}
								</AnimatePresence>
							</TextWrap>
							{isExpandable && (
								<ShowMore onClick={() => setIsExpanded(!isExpanded)}>
									<Flex>
										<Chevron fill='#666' state={isExpanded ? 'up' : 'down'} /> {isExpanded ? strings[language].showLess : strings[language].showMore}
									</Flex>
								</ShowMore>
							)}
						</Content>
					</motion.div>
				</Box>
			</WrapBox>
		</div>
	)
}
export default TextBox
